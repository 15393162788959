import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';


//TODO: DAR UA OLHADA EM CIMADISSO E FAZER MELHOR
const LazyImage = ({ img, children, ...rest }) => {

  const placeholder = useRef();
  const [image, setImage] = useState(false);

  useEffect(() => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting) {
          setImage(true)
        }
      });
    }

    const options = {
      treshold: 1.0
    }

    const observer = new IntersectionObserver(callback, options);
    observer.observe(placeholder.current);

  }, []);



  return (
    <>
    {children ? (
      <div ref={placeholder}>
      {image && children}
    </div>
    ) : (
      <>
      <div ref={placeholder} />
      {image && <img src={img} {...rest} />}
      </>
    )}
    </>
  )

}

// export const LazyWithChildren = ({ children }) => {
//   const placeholder = useRef();
//   const [image, setImage] = useState(false);

//   useEffect(() => {
//     const callback = (entries) => {
//       entries.forEach((entry) => {
//         if(entry.isIntersecting) {
//           setImage(true)
//         }
//       });
//     }

//     const options = {
//       treshold: 1.0
//     }

//     const observer = new IntersectionObserver(callback, options);
//     observer.observe(placeholder.current);

//   }, []);



//   return (
//     <div ref={placeholder}>
//       {image && children}
//     </div>
//   )
// }

export default LazyImage