import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';


const useIntersector = ({ ref }) => {

  const [reference, setReference] = useState(false);
  
  useEffect(() => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting) {
          setReference(true)
        }
      });
    }

    const options = {
      treshold: 1.0
    }

    const observer = new IntersectionObserver(callback, options);
    observer.observe(ref.current);

  }, []);

  if(reference) return true
}

export default useIntersector